const Partner = () => {
    return (
        <div className="partner-section">
            <p className="partner-text text-center">Our Partner Insurer</p>
            <div>
                <img className="partner-logo" src="assets/img/partner-logo.png" alt="max logo" />
            </div>

        </div >
    )
}

export default Partner;
