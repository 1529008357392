import Slider from "react-slick";
import Banner from "./components/Banner";
import Breadcrumbs from "./components/Breadcrumbs";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ServiceBlock from "./components/ServiceBlock";
import Stats from "./components/Stats";
import Benefits from "./components/services/Benefits";
import EligibilityCheck from "./components/services/EligibilityCheck";
import Hero from "./components/services/Hero";
import HowItWorks from "./components/services/HowItWorks";
import KeyFeatures from "./components/services/KeyFeatures";
import testimonial from "./data/testimonial";
import Testimonial from "./components/Testimonial";
import faq from "./data/faq";
import Accordian from "./components/Accordian";
import downloadLink from "./lib/Download";

function AboutUs() {

    const teams = [
        {
            name: "Vinay Babu",
            position: "Founder & CEO",
            linkedin: "https://in.linkedin.com/in/vinay-babu-56a2a52a",
            image: "team/Vinaybabu.jpg"
        },
        {
            name: "Saravanakumar",
            position: "Co-Founder",
            linkedin: "https://in.linkedin.com/in/saravanadev",
            image: "team/Saravana.jpg"
        },
    ]
    const crumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/aboutus',
            label: 'aboutus'
        },

    ]
  const testimonialSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    const banner = {
        title: "Empowering Policyholders with Financial Solutions",
        subtitle: "At Briezi, we simplify and enrich policyholders insurance experience with innovative financial solutions.",
        ctaLabel: "Explore Now",
        ctaLink: downloadLink(),
        image: "assets/img/aboutus-banner.png"
    }

    return (
        <div>
            <Header />
            <div className="px-lg-5 px-2">
                <Breadcrumbs crumbs={crumbs} />
            </div>
            <Banner banner={banner} />
            <ServiceBlock
                title="Our Story"
                subtitle="In 2020, Briezi was founded by insurance industry experts with over 18+ years of experience with the mission of transforming the insurance landscape through innovative financial solutions. We understand the challenges policyholders face in maintaining their life insurance policies and strive to offer products that ensure continuous coverage and financial accessibility."
                points={[]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/aboutus-1.png"
                type="primary"
                imageLocation="left"
            />
            <ServiceBlock
                title="Our Mission"
                subtitle="Our mission is to empower policyholders by offering solutions that make premium payments manageable and provide quick access to funds through our financial solutions. We aim to enhance policy retention and ensure that our clients can enjoy the full benefits of their insurance policies."
                points={[]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/aboutus-2.png"
                type="light"
                imageLocation="right"
            />
            <ServiceBlock
                title="Our Vision"
                subtitle="It is our vision for policyholders to manage their insurance policies in a seamless and stress-free manner and access funds easily. Our goal is to be the leading provider of premium financing, policy assignment, and loan services, empowering policyholders to make the most of their insurance investments."
                points={[]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/aboutus-3.png"
                type="primary"
                imageLocation="left"
            />
            <ServiceBlock
                title="Our Values"
                subtitle="We strive to create a positive impact on the insurance industry by offering services that are transparent, reliable, and beneficial for all stakeholders. Our core values are as follows:"
                points={[
                    "Customer-Centric: We prioritize the needs and satisfaction of our customers in all our endeavors.",
                    "Innovation: We continuously innovate to offer the best financial solutions.",
                    "Integrity: We operate with the highest standards of integrity and transparency.",
                    "Excellence: We strive for excellence in every aspect of our business."
                ]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/aboutus-4.png"
                type="light"
                imageLocation="right"
            />
            <div className="teams-box p-5">
                <div>
                    <p className="text-center meet">Meet</p>
                </div>
                <div className="my-5">
                    <p className="text-center founders-text">The Founders</p>
                </div>
                <div className="row mx-2 px-2 my-2 mx-lg-5 px-lg-5 my-lg-5">
                    <div className="col-12 col-lg-6 px-2 px-lg-2 my-2 my-sm-0" >
                        <div className="team-detail-box">
                            <div className="d-flex align-items-center justify-content-center image-container">
                                <img src="assets/img/vb.jpg" className="team-img" />
                            </div>
                            <div>
                                <p className="text-center team-name">Vinay Babu</p>
                            </div>
                            <div>
                                <p className="text-center designation">Founder & CEO</p>
                            </div>
                            <div className=" px-5">
                                <p className="text-justify profile">Vinay is the driving force behind Briezi. With over 20+ years of experience in the insurance industry, he has a deep understanding of the challenges faced by policyholders and insurers. His vision is to revolutionize the insurance landscape by providing innovative solutions that enhance customer retention and policy persistency. Under his leadership, Briezi has grown into a trusted partner for top insurance companies in India.</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <a href="https://in.linkedin.com/in/vinay-babu-56a2a52a"><img src="assets/img/linkedin-blue.png" className="social-icon" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 px-2 px-lg-2 my-2 my-sm-0" >
                        <div className="team-detail-box">
                            <div className="d-flex align-items-center justify-content-center image-container">
                                <img src="assets/img/sara.jpg" className="team-img" />
                            </div>
                            <div>
                                <p className="text-center team-name">Saravanakumar D</p>
                            </div>
                            <div>
                                <p className="text-center designation">Co-Founder</p>
                            </div>
                            <div className=" px-5">
                                <p className="text-justify profile">Saravana brings a wealth of experience in technology to Briezi. As the Co-Founder, he is responsible for overseeing the software development team and ensuring that our innovative solutions are effectively implemented. With a background in IT, he has been instrumental in developing the Briezi App and other services that set Briezi apart in the industry. His commitment to excellence ensures that we consistently deliver value to our clients.</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <a href="https://in.linkedin.com/in/saravanadev"><img src="assets/img/linkedin-blue.png" className="social-icon" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ServiceBlock
                title="We work with the Best "
                subtitle="We are proud to partner with leading insurance companies like Max Life insurance and HDFC Life Insurance to bring our customers unparalleled financial solutions."
                points={[
                    "Increasing Persistency",
                    "Helping Retain Customers",
                    "Offering Comprehensive Financial Solutions",
                    "Providing Advance Retention Tool"
                ]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/aboutus-4.png"
                type="light"
                imageLocation="right"
            />
            <Stats />
            <div className="testimonial-box p-lg-5 p-2">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <span className="testimonial-box-title">What Our</span>
          </div>
          <div className="fancy-box mx-1">
            <span>Customers</span>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center">
            <span className="testimonial-box-title">Say</span>
          </div>
        </div>
        <div className="p-5">
          <Slider {...testimonialSettings}>
            {testimonial.map(t => (<Testimonial name={t.name} image={t.image} text={t.text} />))}
          </Slider>
        </div>
        <div className="faq-box p-lg-5 p-2">
        <div>
          <p className="text-center faq-title">Frequently Asked Questions</p>
        </div>
        <div className=" p-5">
          <div id="accordion">
            {faq.map(f => ( <Accordian faq={f} />))}
          </div>
        </div>
      </div>
      </div>
            <Footer />
        </div>
    );
}

export default AboutUs;
