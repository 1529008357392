import Slider from "react-slick";
import Banner from "./components/Banner";
import Breadcrumbs from "./components/Breadcrumbs";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ServiceBlock from "./components/ServiceBlock";
import WhatIs from "./components/WhatIs";
import WhySection from "./components/WhySection";
import testimonial from "./data/testimonial";
import Testimonial from "./components/Testimonial";
import faq from "./data/faq";
import Accordian from "./components/Accordian";
import downloadLink from "./lib/Download";

function PolicyLoan() {

    const crumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/',
            label: 'Services'
        },
        {
            link: '/policy-loan',
            label: 'Loan Against Insurance'
        },

    ]
  const testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    const banner = {
        title: "Get Quick Funds Against Your Insurance Policy",
        subtitle: "Loan against insurance policies provides quick and hassle-free liquidity solutions at attractive interest rate.",
        ctaLabel: "Get Funds",
        ctaLink: downloadLink(),
        image: "assets/img/loan-banner.png"
    }

    return (
        <div>
            <Header />
            <div className="px-lg-5 px-2">
                <Breadcrumbs crumbs={crumbs} />
            </div>
            <Banner banner={banner} />
            <WhatIs
                title="What is Loan on Insurance?"
                description="Premium Financing is a convenient financial solution offered by Briezi that helps you pay your insurance premiums with easy EMis. Maintain your insurance coverage without the immediate financial burden, ensuring you and your loved  ones stay protected."
            />
            <WhySection
                why={{
                    title: "Why Loan on Insurance?",
                    description: "Now pay your premiums in easy EMIs, ensuring continuous coverage without financial strain.",
                    iconSet: [
                        {
                            icon: "assets/img/paperless-process-icon.png",
                            caption: "100% Digital Process"
                        },
                        {
                            icon: "assets/img/customer-support-icon.png",
                            caption: "Quick Approval"
                        },
                        {
                            icon: "assets/img/simple-secure-icon.png",
                            caption: "Low-Interest Rates"
                        },
                        {
                            icon: "assets/img/minimal-document-icon.png",
                            caption: "Flexible Repayment Options"
                        }
                    ],
                    videoLink: "https://www.youtube.com/embed/CevYS_p-BiQ?si=SJYLlj3omO9rawup"
                }} />

            <ServiceBlock
                title="Why Choose Loan on Insurance?"
                subtitle=""
                points={["Immediate Funds - Access cash quickly in times of need.", "Low Interest Rates - Affordable borrowing options.", "Retain Coverage - Keep your insurance policy active.", "Flexible Repayment Options - Convenient repayment terms to suit your needs."]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/why-loan.png"
                type="primary"
                imageLocation="right"
            />
            <ServiceBlock
                title="How It Works"
                subtitle=""
                points={["Apply Online: Fill out a simple application form with your policy details.", "Get Quick Approval: Insurer reviews your application and approves it.", "Quick Disbursement: The funds are disbursed directly to your bank account.", "Receive Funds - Access your loan amount directly to your bank account."]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/how-loan.png"
                type="light"
                imageLocation="left"
            />
            <ServiceBlock
                title="How to apply for Loan to Pay Premiums?"
                subtitle=""
                points={["Get the Briezi app","Apply for loan against your policy","Complete KYC verification","Instant loan approval"]}
                ctaLabel="Download App"
                ctaLink="/"
                image="assets/img/how-to-apply-premium-finance.png"
                type="primary"
                imageLocation="right"
            />
            <ServiceBlock
                title="Eligibility Criteria"
                subtitle=""
                points={["Policy Type: The policy must be a life insurance  traditional savings or ULIP policies.","Active Policy: Must have an active insurance policy.","Policy Value: Policy must have sufficient value for the loan amount.","Valid Documents: Valid Aadhar card, PAN card, and Policyholders Bank Details."]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/eligibility-loan.png"
                type="light"
                imageLocation="left"
            />
            <div className="testimonial-box p-lg-5 p-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <span className="testimonial-box-title">What Our</span>
                    </div>
                    <div className="fancy-box mx-1">
                        <span>Customers</span>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <span className="testimonial-box-title">Say</span>
                    </div>
                </div>
                <div className="p-5">
                    <Slider {...testimonialSettings}>
                        {testimonial.map(t => (<Testimonial name={t.name} image={t.image} text={t.text} />))}
                    </Slider>
                </div>
            </div>
            <div className="faq-box p-lg-5 p-2">
                <div>
                    <p className="text-center faq-title">Frequently Asked Questions</p>
                </div>
                <div className=" p-5">
                    <div id="accordion">
                        {faq.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
            </div>
            <ContactUs />
            <Footer />
        </div>
    );
}

export default PolicyLoan;
