const Stats = () => {
    return (
        <div className="stats-box p-5">
            <div>
                <p className="text-center stats-title">Empowering policyholders to secure their financial futures.</p>
            </div>
            <div className="row p-5">
                <div className="col-12 col-sm-6 col-lg-3">
                    <p className="text-center stats-number">136 K +</p>
                    <p className="text-center stats-number-text">Policyholders</p>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <p className="text-center stats-number">₹ 1.4 Cr+</p>
                    <p className="text-center stats-number-text">Life Cover Restored</p>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <p className="text-center stats-number">204 K+</p>
                    <p className="text-center stats-number-text">Life Insurance Policies</p>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <p className="text-center stats-number">336</p>
                    <p className="text-center stats-number-text">Insurance Distributors</p>
                </div>
            </div>
        </div>
    )
}

export default Stats;
