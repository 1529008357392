import Slider from "react-slick";
import Banner from "./components/Banner";
import Breadcrumbs from "./components/Breadcrumbs";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ServiceBlock from "./components/ServiceBlock";
import WhatIs from "./components/WhatIs";
import WhySection from "./components/WhySection";
import testimonial from "./data/testimonial";
import Testimonial from "./components/Testimonial";
import faq from "./data/faq";
import Accordian from "./components/Accordian";
import downloadLink from "./lib/Download";

function PolicyLoan() {

    const crumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/',
            label: 'Services'
        },
        {
            link: '/assignment',
            label: 'Policy ASsignment'
        },

    ]
  const testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    const banner = {
        title: "Unlock the Value of Your Policy with Policy Assignment",
        subtitle: "Get policy value while continue to get risk coverage at zero cost.",
        ctaLabel: "Check Eligibility",
        ctaLink: downloadLink(),
        image: "assets/img/assignment-banner.png"
    }

    return (
        <div>
            <Header />
            <div className="px-lg-5 px-2">
                <Breadcrumbs crumbs={crumbs} />
            </div>
            <Banner banner={banner} />
            <WhatIs
                title="What is Policy Assignment?"
                description="Policy Assignment allows you to transfer your insurance policy to a third party in exchange for financial benefits. This  service helps you access funds without surrendering your policy, so you can continue to enjoy life coverage while receiving the money you need for your financial needs and you don’t have to pay future premiums."
            />
            <WhySection
                why={{
                    title: "Why Policy Assignment?",
                    description: "Get surrender value on savings insurance policy and enjoy risk coverage at zero cost.",
                    iconSet: [
                        {
                            icon: "assets/img/paperless-process-icon.png",
                            caption: "Immediate Liquidity"
                        },
                        {
                            icon: "assets/img/simple-secure-icon.png",
                            caption: "Continuous Coverage"
                        },
                        {
                            icon: "assets/img/customer-support-icon.png",
                            caption: "Quick Approval"
                        },
                        {
                            icon: "assets/img/minimal-document-icon.png",
                            caption: "Secure Process"
                        }
                    ],
                    videoLink: "https://www.youtube.com/embed/CevYS_p-BiQ?si=SJYLlj3omO9rawup"
                }} />

            <ServiceBlock
                title="Why Choose Policy Assignment?"
                subtitle=""
                points={["Immediate Liquidation: Access surrender value without losing coverage.","Continuous Coverage - Keep your life insurance active even after assignment.","Quick Approval: Simple digital process with instant approval. ","Secure Process: Safe and secure assignment process."]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/why-assignment.png"
                type="primary"
                imageLocation="right"
            />
            <ServiceBlock
                title="How It Works"
                subtitle=""
                points={["Initiate Assignment: Initiate from the Briezi mobile app and e-sign the policy assignment application digitally.", "Get Quick Approval: Insurer reviews your application and approves it.", "Quick Disbursement: Funds are disbursed directly to your bank account.","Policy Continuity: Ensure continuity of your insurance coverage which secures your family's future."]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/how-assignment.png"
                type="light"
                imageLocation="left"
            />
            <ServiceBlock
                title="How to apply for Policy Assignment?"
                subtitle=""
                points={["Get the Briezi app","Check assignment eligibility","Assign your Policy","Receive Funds "]}
                ctaLabel="Download App"
                ctaLink={downloadLink()}
                image="assets/img/how-to-apply-assignment.png"
                type="primary"
                imageLocation="right"
            />
            <ServiceBlock
                title="Eligibility Criteria"
                subtitle=""
                points={["Policy Type: The policy must be a life insurance  traditional savings or ULIP policies.","Active Policy: Must have an active insurance policy.","Policy Value: Premium payments for traditional insurance policies must be made for at least 5 years and 2 year for ULIPs.","Valid Documents: Valid Aadhar card, PAN card, and Policyholders Bank Details."]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/eligibility-assignment.png"
                type="light"
                imageLocation="left"
            />
            <div className="testimonial-box p-lg-5 p-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <span className="testimonial-box-title">What Our</span>
                    </div>
                    <div className="fancy-box mx-1">
                        <span>Customers</span>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <span className="testimonial-box-title">Say</span>
                    </div>
                </div>
                <div className="p-5">
                    <Slider {...testimonialSettings}>
                        {testimonial.map(t => (<Testimonial name={t.name} image={t.image} text={t.text} />))}
                    </Slider>
                </div>
            </div>
            <div className="faq-box p-lg-5 p-2">
                <div>
                    <p className="text-center faq-title">Frequently Asked Questions</p>
                </div>
                <div className=" p-5">
                    <div id="accordion">
                        {faq.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
            </div>
            <ContactUs />
            <Footer />
        </div>
    );
}

export default PolicyLoan;
