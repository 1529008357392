import moment from "moment";

const Footer = () => {
    return (
        <div className="footer-section container-fluid">
            <div className="row">
                <div className="col-lg-3 col-12">
                    <img className="white-logo" src="assets/img/white-logo.png" alt="" />
                    <div>
                        <p className="white-text footer-text">Copyright © {moment().format('YYYY')} Briezi Insuretech Pvt Ltd.</p>
                    </div>
                    <div>
                        <p className="white-text footer-text">All rights reserved</p>
                    </div>
                    <div className="d-flex flex-row">
                        <a className="mr-2" href="https://www.instagram.com/briezi_insuretech/"><div><img src="/assets/img/instagram.png" className="social-icon" /></div></a>
                        <a className="mx-2" href="https://www.linkedin.com/company/briezi-insuretech-private-limited"><div><img src="/assets/img/linkedin.png" className="social-icon" /></div></a>
                        <a className="mx-2" href="https://www.facebook.com/Brieziinsuretech/"><div><img src="/assets/img/facebook.png" className="social-icon" /></div></a>
                        <a className="mx-2" href="https://www.youtube.com/@brieziinsuretechpvtltd6175"><div><img src="/assets/img/youtube.png" className="social-icon" /></div></a>
                    </div>
                </div>
                <div className="col-lg-9 col-12">
                    <div className="row">
                        <div className="col-lg-4 col-12">
                            <h3 className="white-text footer-text-title">Company</h3>
                            <a href="/aboutus" className="white-text footer-text"><p>About us</p></a>
                            <a href="/premium-financing" className="white-text footer-text"><p>Premium Financing</p></a>
                            <a href="/policy-loan" className="white-text footer-text"><p>Policy Loan</p></a>
                            <a href="/assignment" className="white-text footer-text"><p>Assignment</p></a>
                            <a href="mailto:info@briezi.com" className="white-text footer-text"><p>Contact us</p></a>
                        </div>
                        <div className="col-lg-4 col-12">
                            <h3 className="white-text footer-text-title">Support</h3>
                            <p className="white-text footer-text">support@briezi.com</p>
                            <a href="/terms" className="white-text footer-text"><p>Terms of Service</p></a>
                            <a href="/privacy" className="white-text footer-text"><p>Privacy Policy</p></a>
                        </div>
                        <div className="col-lg-4 col-12">
                            <h3 className="white-text footer-text-title">NBFC Partner</h3>
                            <p className="white-text footer-text">Radiant Equity Management Pvt Ltd.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
