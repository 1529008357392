import Slider from "react-slick"
import Banner from "./components/Banner"
import Breadcrumbs from "./components/Breadcrumbs"
import ContactUs from "./components/ContactUs"
import Footer from "./components/Footer"
import Header from "./components/Header"
import ServiceBlock from "./components/ServiceBlock"
import testimonial from "./data/testimonial"
import Testimonial from "./components/Testimonial"
import Stats from "./components/Stats"
import Partner from "./components/Partner"
import faq from "./data/faq"
import Accordian from "./components/Accordian"

const Investors = () => {
    const crumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/',
            label: 'Partner With Us'
        },
        {
            link: '/assignment',
            label: 'Investors'
        },

    ]
  const testimonialSettings = {
    dots: false,
    autoplay:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    const banner = {
        title: "Partner with Briezi - Maximize Your Investment Potential",
        subtitle: "Invest in secure, high-growth opportunities with Briezi.",
        ctaLabel: "Get Started",
        ctaLink: "/contactus",
        image: "assets/img/investor-banner.png"
    }
    return (
        <div>
            <Header />
            <div className="px-lg-5 px-2">
                <Breadcrumbs crumbs={crumbs} />
            </div>
            <Banner banner={banner} />
            <ServiceBlock
                title="Why Invest with Briezi?"
                subtitle=""
                points={["High-Yield Investments", "Secure Investments", "Diversified Portfolio", "Proven Returns"]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/why-investment.png"
                type="primary"
                imageLocation="right"
            />
            <ServiceBlock
                title="How Our Partnership Works"
                subtitle=""
                points={["Initial Consultation: We begin with a detailed consultation to understand your investment goals and objectives.", "Tailored Investment Strategies: Develop tailored investment strategies based on your risk appetite and financial goals.", "Secure, High-Yield Investments: Invest in secure, high-yield financial products designed to offer consistent returns.", "Ongoing Support and Insights: Receive ongoing support and market insights to ensure your investments perform optimally."]}
                ctaLabel="Connect now"
                ctaLink="/contactus"
                image="assets/img/how-partnership-works.png"
                type="light"
                imageLocation="left"
            />
            <div className="testimonial-box-light p-lg-5 p-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <div className="fancy-box mx-1">
                        <span>Success Stories</span>
                    </div>
                </div>
                <div className="px-5 my-5">
                    <p className="subtitle text-center px-lg-5 p-2">
                        We have a proven track record of delivering exceptional returns to our investors. Read our success stories and learn how partnering with Briezi can benefit you.
                    </p>
                </div>
                <div className="p-lg-5 p-2">
                    <Slider {...testimonialSettings}>
                        {testimonial.map(t => (<Testimonial name={t.name} image={t.image} text={t.text} />))}
                    </Slider>
                </div>
            </div>
            <Partner />
            <Stats />
            <div className="contact-us-section container-fluid">
                <div className="row">
                    <div className="col-12  my-4">
                        <p className="text-center contactus-title">Ready to Partner with Briezi?</p>
                    </div>
                    <div className="col-12">
                        <p className="text-center">Join the growing list of financial institutions leveraging our solutions to enhance their loan portfolios and get secure returns.</p>
                    </div>

                    <div className="d-flex justify-content-center align-item-center col-12 mb-4">
                        <div className="slide-cta primary-button my-3">
                            <a className="nav-link myHref" href={"mailto:info@briezi.com"}  >{"Contact Us"}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-box p-lg-5 p-2">
                <div>
                    <p className="text-center faq-title">Frequently Asked Questions</p>
                </div>
                <div className=" p-5">
                    <div id="accordion">
                        {faq.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Investors