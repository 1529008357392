const SolutionItem = (props) => {
    return(
        <div className="solution-item mx-lg-4 mx-2 p-lg-5 p-2 d-flex flex-column justify-content-center align-items-center">
            <div>
                <img src={props.icon} className="solution-icon" alt="" />
            </div>
            <div>
                <p className="text-center solution-title">{props.solution}</p>
            </div>
            <div>
                <p className="text-center solution-description">{props.description}</p>
            </div>
        </div>
    )
}

export default SolutionItem