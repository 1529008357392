const faq = [
    {
        "q": "What is Policy Assignment?",
        "a": "Policy assignment is the transfer of rights of a life insurance policy from the policy owner (assignor) to a third party (assignee), typically in exchange for a financial benefit. According to Section 38 of the Insurance Act, of 1938, Policy assignment is legal in India.",
        "id": "1",
        "type": "assignment"
    },
    {
        "q": "How does Policy Assignment Work?",
        "a": "The policy owner assigns the rights of the policy to a third party (assignee) who provides a financial benefit. The assignee becomes the new beneficiary of the policy.",
        "id": "2",
        "type": "assignment"
    },
    {
        "q": "What are the Benefits of Policy Assignment?",
        "a": "Policy assignment offers surrender value by providing faster liquidity, the policyholder continues to get risk coverage and avoids payment of future premiums",
        "id": "3",
        "type": "assignment"
    }, {
        "q": "Is Policy Assignment Secure?",
        "a": "Yes, policy assignment with Briezi is a secure and hassle-free process. Your personal information and policy details are protected with advanced encryption and security measures.",
        "id": "4",
        "type": "assignment"
    },
    {
        "q": "How can I Initiate a Policy Assignment with Briezi",
        "a": "Simply log in to your Briezi account, navigate to the policy assignment section, and follow the step-by-step instructions to initiate the process securely online.",
        "id": "5",
        "type": "assignment"
    },
    {
        "q": "Can I use the loan for any type of insurance policy?",
        "a": "Are there any Fees or Charges for Policy Assignment?",
        "id": "6",
        "type": "assignment"
    },
    {
        "q": " How long does the Policy Assignment Process Take?",
        "a": "The policy assignment process with Briezi is fast and efficient, typically completed within a few days once all necessary documents and information are provided.",
        "id": "7",
        "type": "assignment"
    },
    {
        "q": "Can I Cancel or Reverse Policy Assignment?",
        "a": "Once a policy assignment is completed, it cannot be reversed or cancelled. It's important to carefully consider your decision before proceeding with the assignment",
        "id": "8",
        "type": "assignment"
    },
    {
        "q": "Will I Lose Ownership of My Policy after Assignment?",
        "a": "While the rights of the policy are transferred to the assignee you will lose the ownership of the policy, but you’ll continue to get the risk coverage as agreed upon in the assignment agreement.",
        "id": "9",
        "type": "assignment"
    },
    {
        "q": "How can I Learn More about Policy Assignment with Briezi?",
        "a": "For more information or assistance with policy assignment, please contact our customer support team or refer to the policy assignment section on the Briezi website",
        "id": "10",
        "type": "assignment"
    },
    {
        "q": "Is policy assignment legal in India?",
        "a": "According to Section 38 of the Insurance Act, of 1938, Policy assignment is legal in India.",
        "id": "11",
        "type": "assignment"
    }
]

export default faq